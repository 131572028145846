<template>
    <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
        <router-view/>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "LayoutForPointOfSales",
        mounted() {
            // check if current user is authenticated
            if (!this.isAuthenticated) {
                this.$router.push({name: "login"});
            }

        },
        methods: {},
        computed: {
            ...mapGetters([
                "isAuthenticated",
                "breadcrumbs",
                "pageTitle",
                "layoutConfig"
            ]),

            /**
             * Check if the page loader is enabled
             * @returns {boolean}
             */
            loaderEnabled() {
                return !/false/.test(this.layoutConfig("loader.type"));
            },

            /**
             * Check if container width is fluid
             * @returns {boolean}
             */
            contentFluid() {
                return this.layoutConfig("content.width") === "fluid";
            },

            /**
             * Page loader logo image using require() function
             * @returns {string}
             */
            loaderLogo() {
                return process.env.BASE_URL + this.layoutConfig("loader.logo");
            },

            /**
             * Check if the left aside menu is enabled
             * @returns {boolean}
             */
            asideEnabled() {
                return !!this.layoutConfig("aside.self.display");
            },

            /**
             * Set the subheader display
             * @returns {boolean}
             */
            subheaderDisplay() {
                return !!this.layoutConfig("subheader.display");
            }
        }
    };
</script>

<style>
body {
  background: #fff;
}
</style>